import { render, staticRenderFns } from "./CreateQuestionModal.vue?vue&type=template&id=5c16dca7&scoped=true&"
import script from "./CreateQuestionModal.vue?vue&type=script&lang=js&"
export * from "./CreateQuestionModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateQuestionModal.vue?vue&type=style&index=0&id=5c16dca7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c16dca7",
  null
  
)

export default component.exports