var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"create-question",attrs:{"visible":_vm.questionVisible,"width":576,"title":"创建问题","okText":"提交"},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('div',{staticClass:"question-box"},[_c('a-form-model',{ref:"question",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{ span: 4 },"wrapper-col":{ span: 19 }}},[_c('a-form-model-item',{attrs:{"label":"问题内容","required":"","prop":"questionContext"}},[_c('a-textarea',{attrs:{"placeholder":"请输入您的问题......","auto-size":{ minRows: 3, maxRows: 5 },"maxlength":500},model:{value:(_vm.form.questionContext),callback:function ($$v) {_vm.$set(_vm.form, "questionContext", $$v)},expression:"form.questionContext"}})],1),_c('a-form-model-item',{attrs:{"label":"语言对"}},[_c('a-row',[_c('a-col',{attrs:{"span":11}},[_c('a-form-model-item',{attrs:{"validate-status":_vm.getValidateStatus(
                  _vm.form.sourceCode,
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : '',
                  _vm.form.sourceCode
                ),"help":_vm.getValidateTip(
                  _vm.form.sourceCode,
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : '',
                  _vm.form.sourceCode
                )}},[_c('a-select',{attrs:{"allowClear":"","show-search":"","filter-option":_vm.langFilterOption,"placeholder":_vm.$t('orderInfo.source')},model:{value:(_vm.form.sourceCode),callback:function ($$v) {_vm.$set(_vm.form, "sourceCode", $$v)},expression:"form.sourceCode"}},_vm._l((_vm.sourceCodeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"source-target"},[_vm._v(">")])]),_c('a-col',{attrs:{"span":11}},[_c('a-form-model-item',{attrs:{"validate-status":_vm.getValidateStatus(
                  _vm.form.sourceCode,
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : '',
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : ''
                ),"help":_vm.getValidateTip(
                  _vm.form.sourceCode,
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : '',
                  _vm.form.targetCodeList ? _vm.form.targetCodeList.join(',') : ''
                )}},[_c('a-select',{attrs:{"allowClear":"","show-search":"","filter-option":_vm.langFilterOption,"placeholder":_vm.$t('orderInfo.target'),"mode":"multiple"},model:{value:(_vm.form.targetCodeList),callback:function ($$v) {_vm.$set(_vm.form, "targetCodeList", $$v)},expression:"form.targetCodeList"}},_vm._l((_vm.targetCodeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1),_c('a-form-model-item',{attrs:{"label":"附件"}},[_c('NeoUploadButton',{attrs:{"title":"上传附件","showNote":_vm.form.attachmentFile.length > 0 ? false : true,"showFileList":true,"showIcon":true,"accept":".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .zip","maxSize":500,"maxLength":1,"note":"支持doc、docx、xlsx、xls、pdf、png、jpg、zip格式，且大小不超过500M"},model:{value:(_vm.form.attachmentFile),callback:function ($$v) {_vm.$set(_vm.form, "attachmentFile", $$v)},expression:"form.attachmentFile"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }